import api from "./api";

const addActivityPartResult = (activityPartResult) => {
    return api.post(`/result/`, activityPartResult);
};


const ResultService = {
    addActivityPartResult,

};
export default ResultService;
