import React, {useEffect, useState} from "react";
import PageNavigation from "../components/PageNavigation";
import {Link, Navigate, useParams} from "react-router-dom";

import ExamService   from "../../service/exam.service";

export default function StudentExamList() {
    // /start/student/:studentId/name/:name/lastname/:lastname/email/:email/mobilePhone/:mobilePhone/school/:school/grade/:grade/branch/:branch/exam/:examId
    let {studentId, name, lastname, email, mobilePhone, school, grade, branch, examId} = useParams();
    const [examList, setExamList] = useState([]);
    const [isReady, setIsReady] = useState("waiting");

    useEffect(() => {
        ExamService.getAllExam().then((response) => {
            setExamList(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(examId && studentId){
            localStorage.setItem("studentId", studentId);
            localStorage.setItem("name", name);
            localStorage.setItem("lastname", lastname);
            localStorage.setItem("email", email);
            localStorage.setItem("mobilePhone", mobilePhone);
            localStorage.setItem("school", school);
            localStorage.setItem("grade", grade);
            localStorage.setItem("branch", branch);
            localStorage.setItem("examId", examId);
            ExamService.getExamById(examId).then((response) => {
                if(response.data){
                    setIsReady("ready");
                }
                else{
                    setIsReady("error");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);






    return (
        <React.Fragment>


            <div className="container">
                <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">
                    <div className="d-flex mb-3">
                    </div>
                    {
                        isReady === "ready" ? <Navigate to="/"/> :
                            isReady === "error" ? <h1>HATALI GİRİŞ</h1> : null
                    }
                </div>
            </div>

            <div>

                {//studentId}, {name}, {lastname}, {email}, {mobilePhone}, {school}, {grade}, {branch}, {examId
                }
            </div>


        </React.Fragment>
    )
}