import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import ExamService   from "../../service/exam.service";

export default function Exam() {

    const [examList, setExamList] = useState([]);
    const [user, setUser] = useState(null);

    const [examData, setExamData] = useState(null);



    useEffect(() => {
        const studentId = localStorage.getItem("studentId: ");
        const name = localStorage.getItem("name: ");
        const lastname = localStorage.getItem("lastname: ");
        const email = localStorage.getItem("email: ");
        const mobilePhone = localStorage.getItem("mobilePhone: ");
        const school = localStorage.getItem("school: ");
        const grade = localStorage.getItem("grade: ");
        const branch = localStorage.getItem("branch: ");
        const examId = localStorage.getItem("examId: ");

        setUser({
            studentId: studentId,
            name: name,
            lastname: lastname,
            email: email,
            mobilePhone: mobilePhone,
            school: school,
            grade: grade,
            branch: branch,
            examId: examId
        })

        if(studentId && name && lastname && school && grade && branch && examId){
            ExamService.startExam(studentId, name, lastname, email, mobilePhone, school, grade, branch, examId).then((response) => {
                setExamData(response.data);
            });
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("studentId")]);



    useEffect(() => {
        ExamService.getAllExam().then((response) => {
            setExamList(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const examRow = () => {
        return (
            examList && Array.isArray(examList)  && user ?
                examList.map((exam, key) =>
                    exam.id === user.examId ?
                        <tr key={key}>
                            <td>
                                <Link to="#" className="d-flex align-items-center"
                                >
                                    <div
                                        className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3"
                                    >
                                        <span>{key+1}</span>
                                    </div>
                                    <div className="d-block">
                                        <span className="fw-bold">{exam.name}</span>
                                        <div className="small text-gray">
                                            <span className="__cf_email__">{exam.explain}</span>
                                        </div>
                                    </div>
                                </Link>
                            </td>
                            <td>
                <span className="fw-normal d-flex align-items-center">
                    <svg
                        className="icon icon-xxs text-success me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd">
                    </path>
                  </svg>
                  Email</span>
                            </td>
                            <td>
                                {
                                    exam.status ?
                                        <span className="fw-normal text-success">Active</span> :
                                        <span className="fw-normal text-danger">Suspended</span>
                                }
                            </td>
                            <td>
                                <Link
                                    to={`/exam/${exam.id}`}
                                    className="btn btn-icon-only btn-youtube d-inline-flex align-items-center"
                                    type="button">
                                    <svg
                                        className="icon icon-xxs"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fab"
                                        data-icon="youtube"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512">
                                        <path
                                            fill="currentColor"
                                            d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                                        ></path>
                                    </svg>
                                </Link
                                >
                            </td>
                        </tr> : null
                )
                : null
        )
    }

    return (
        <div className="container">
            <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">
                <div className="d-flex mb-3">
                     <h2>Speaking Exam List</h2>
                </div>
                <table className="table user-table table-hover align-items-center">
                    <thead>
                    <tr>
                        <th className="border-bottom">Name</th>
                        <th className="border-bottom">Verified</th>
                        <th className="border-bottom">Status</th>
                        <th className="border-bottom">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        examRow()
                    }
                    </tbody>
                </table>

            </div>
        </div>
    )
}