import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";
import SpeechText from "../../spech/SpeechText";
import PlayerMy from "../../player/PlayerMy";
import ResultService from "../../service/result.service";
import ReactPlayer from "react-player";

var stringSimilarity = require("string-similarity");

export default function DoExam() {
    const [exam, setExam] = useState(null);
    const [qNumber, setQNumber] = useState(-1);
    const [question, setQuestion] = useState(null);

    const [examResult, setExamResult] = useState(true);
    const [examResultActivityPart, setExamResultActivityPart] = useState(true);
    const [student, setStudent] = useState(true);

    useEffect(() => {

        const studentId = localStorage.getItem("studentId");
        const name = localStorage.getItem("name");
        const lastname = localStorage.getItem("lastname");
        const email = localStorage.getItem("email");
        const mobilePhone = localStorage.getItem("mobilePhone");
        const school = localStorage.getItem("school");
        const grade = localStorage.getItem("grade");
        const branch = localStorage.getItem("branch");
        const examId = localStorage.getItem("examId");

        if (studentId && name && lastname && school && grade && branch && examId) {
            ExamService.startExam(studentId, name, lastname, email, mobilePhone, school, grade, branch, examId).then((response) => {
                const result = response.data
                setExamResult(response.data.examResult);
                setExamResultActivityPart(response.data.examResultActivityPart);
                setStudent(response.data.student);
                setExam(response.data.exam);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (exam && exam.activities && Array.isArray(exam.activities) && exam.activities.length > 0 && qNumber === -1) {
            setQNumber(0)
            setQuestion(exam.activities[0])

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exam]);

    useEffect(() => {
        setQuestion(null)
        if (qNumber > -1) {
            setQuestion(exam.activities[qNumber])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qNumber]);


    const handleQuestionSelect = (value) => {
        if (value === -1 && qNumber > 0) {
            setQNumber(qNumber - 1)
        } else if (value === 1 && qNumber < exam.activities.length - 1) {
            setQNumber(qNumber + 1)
        }
    }


    const clearText = (readnigText) => {
        let newText = String(readnigText).replaceAll(".", "").replaceAll("'", "").replaceAll("!", "").replaceAll(",", "").replaceAll("’", "").replaceAll("?", "").replaceAll("-", "").replaceAll("_", "");
        newText = newText.toLowerCase();
        return newText;
    }
    const getSpeechText = (text, questionNumber) => {
        const selectedAnswer = question.parts.find(part => part.id === questionNumber).answer
        const similarity = stringSimilarity.compareTwoStrings(clearText(text), clearText(selectedAnswer));
        const value = parseInt(similarity * 100)
        const activityPartResult = examResultActivityPart.find(part => part.activityPart.id === questionNumber)
        const score = activityPartResult.score;
        const studentScore = value * score / 100;
        if (!activityPartResult.studentScore || Number(studentScore) > Number(activityPartResult.studentScore)) {
            activityPartResult.studentAnswer = text;
            activityPartResult.studentScore = studentScore;
            ResultService.addActivityPartResult(activityPartResult).then((response) => {
                setExamResultActivityPart(response.data)
            });
        }
    };

    const findResultData = (ques) => {
        if (!ques.isMain) return null;
        const activityPartResult = examResultActivityPart.find(part => part.activityPart.id === ques.id)
        const activeActivity = question.parts.find(part => part.id === ques.id)
        return {
            repeatCount: activeActivity?.repeatCount,
            studentRepeatCount: activityPartResult?.repeatCount,
            studentScore: activityPartResult?.studentScore
        }
    }

    const handleEnded = (activityPartId) => {
        console.log('onEnded', activityPartId)
        const activityPartResult = examResultActivityPart.find(part => part.activityPart.id === activityPartId)
        activityPartResult.repeatCount = activityPartResult.repeatCount ? activityPartResult.repeatCount + 1 : 1
        //setPlaying(loop)
        ResultService.addActivityPartResult(activityPartResult).then((response) => {
            setExamResultActivityPart(response.data)
        });
    }


    const selectAudio = (ques) => {
        const resultData = findResultData(ques)

        return (
            <div className="d-flex align-items-center justify-content-center m-2">
                {
                    ques.isMain === false || resultData?.repeatCount !== resultData?.studentRepeatCount ?
                        <PlayerMy url={`http://localhost:3000/sample/${ques.url}`} handleEnded={handleEnded}
                                  activityPArtId={ques.id}/> : null
                }

                {
                    resultData?.repeatCount && resultData.repeatCount > 0 ? <div
                        className="badge bg-success me-2">{resultData?.repeatCount} -{resultData?.studentRepeatCount}</div> : null
                }
            </div>
        )
    }

    const selectImage = (ques) => {
        const resultData = findResultData(ques)
        return (
            <div className={"text-center"}>
                <img src={`http://localhost:3000/sample/${ques.url}`} style={{height: "250px"}} alt="image"/>
            </div>
        )
    }
    const selectVideo = (ques) => {
        const resultData = findResultData(ques)
        return (
            <div className={"d-flex justify-content-center align-items-center mb-2"}>
                {
                    // <ReactPlayer playsinline={true} controls url={`http://localhost:3000/sample/${url}`}/>
                    //                 <CustomPlayer url={`http://localhost:3000/sample/${url}`}/>
                }

                <PlayerMy url={`http://localhost:3000/sample/${ques.url}`} style={{height: "250px"}}/>
            </div>
        )
    }

    const selectText = (ques) => {
        const resultData = findResultData(ques)

        const lineList = ques.content.split("\n");
        return (
            <div className={"text-left"}>
                <h5>{
                    lineList.map((line, key) =>
                        <div key={key}>{line}</div>
                    )
                }
                </h5>
            </div>
        )
    }


    const compare = (a, b) => {
        if (a.orderNumber < b.orderNumber) {
            return -1;
        }
        if (a.orderNumber > b.orderNumber) {
            return 1;
        }
        return 0;
    };


// <Link to={`/exam/${id}/${question.id}/${activityPart.id}`}> <FaRegEdit cursor={"pointer"} size={24} onClick={() => handleQuestionSelect(-1)} className="m-2"/></Link>


    const makeChoice = (key, ques) => {


        return (
            //<div className={`form-check alert alert-light border ${question.answer === key + "" ? "border-info" : "border-success"}  w-100`} role="alert">
            <div key={key}
                 className={`form-check alert alert-light border border-gray w-100`}>
                {
                    ques.type === "TEXT" ? selectText(ques) :
                        ques.type === "AUDIO" ? selectAudio(ques) :
                            ques.type === "VIDEO" ? selectVideo(ques) :
                                ques.type === "IMAGE" ? selectImage(ques) :
                                    null
                }
                {
                    ques.isMain ? <>
                            <SpeechText
                                getSpeechText={getSpeechText}
                                questionNumber={ques.id}
                            />

                        </> :
                        null

                }


            </div>
        )
    }

    return (
        <div className="container">
            {
                question ?
                    <React.Fragment>
                        <div className="row justify-content-center mt-3">
                            <div className="col-12">
                                <div className="card border-0 shadow p-4 mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                  <span className="font-small">
                                      <Link to="#">
                                          <h1 className="fw-bold">{exam.name}</h1>
                                    </Link>

                                  </span>
                                        <div className="d-none d-sm-block">
                                            <svg
                                                className="icon icon-xs"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                    <p className="m-0">
                                        {exam.explain}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment> : null}


            {
                question ?
                    <React.Fragment>
                        <div className="row justify-content-center mt-3">
                            <div className="col-12">
                                <div className="card border-0 shadow p-4 mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                  <span className="font-small">
                                          <h4 className="fw-bold">Activity {(qNumber + 1)} : {question.activityGroup.replaceAll("_", " ")}</h4>



                                  </span>
                                    </div>
                                    {
                                        question && question.parts && Array.isArray(question.parts) ?
                                            question.parts.sort(compare).map((ques, key) =>
                                                <div key={key} className="mt-2">
                                                    <div className="col-lg-3 col-sm-6 mt-4 mt-md-0 w-100">
                                                        <fieldset className="w-100">
                                                            {
                                                                makeChoice(key, ques)
                                                            }
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            )

                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div
                            className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination mb-0">
                                    <li className="page-item">
                                        <button className="page-link" onClick={() => handleQuestionSelect(-1)}>Previous
                                        </button>
                                    </li>

                                    {
                                        exam && exam.activities && Array.isArray(exam.activities) ?
                                            exam.activities.sort(compare).map((exam, key) =>
                                                <li key={key} className={`page-item`}>
                                                    <button
                                                        className={`page-link ${qNumber === key ? "bg-success" : !exam.answer && exam.answer === "" ? "" : "bg-gray-100"}`}
                                                        onClick={() => setQNumber(key)}>{key + 1}</button>
                                                </li>
                                            ) : null
                                    }
                                    <li className="page-item">

                                        {
                                            qNumber < exam.activities.length - 1 ?

                                                <button className="page-link"
                                                        onClick={() => handleQuestionSelect(1)}>Next
                                                </button>
                                                : <button className="page-link"
                                                          onClick={() => alert("Finish Exam")}>Finish Exam
                                                </button>

                                        }

                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </React.Fragment> : null}
        </div>
    )
}