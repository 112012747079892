import React, { useState, useEffect, useContext } from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import { useRole } from "../../hooks/useRole";
import { AppContext } from "../../../configs/AppContextProvider";
import { NotificationContainer } from 'react-notifications';

const logo = require("../../../assets/logo_elta.png")


export default function Header() {
  const { tenant } = useContext(AppContext);
  let location = useLocation();
  const [role, checkRole] = useRole(true);
  const [visibility, setVisibility] = useState(true);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    checkRole("menu", location.pathname, "Admin")
  }, [checkRole, location.pathname]);




  useEffect(() => {


  }, []);


  useEffect(() => {
    setVisibility(role)
  }, [role]);


  useEffect(() => {
    const studentId = localStorage.getItem("studentId");
    const name = localStorage.getItem("name");
    const lastname = localStorage.getItem("lastname");
    const email = localStorage.getItem("email");
    const mobilePhone = localStorage.getItem("mobilePhone");
    const school = localStorage.getItem("school");
    const grade = localStorage.getItem("grade");
    const branch = localStorage.getItem("branch");
    const examId = localStorage.getItem("examId");

    setUser(`${name} ${lastname}`)

  }, [localStorage.getItem("studentId")]);

  const sing_out = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("user_data")
    window.location.reload(false);
    navigate("/sing-in");
  }

  return (<React.Fragment>
    {visibility === true ?
      <nav className="card navbar navbar-top navbar-expand navbar-dashboard navbar-dark p-4 m-2 mb-5">
        <div className="container-fluid px-0">
          <div
            className="d-flex justify-content-between w-100"
            id="navbarSupportedContent"
          >
            <div className="d-flex align-items-center">
            <img src={logo} alt="logo" style={{  height: '100px' }} />
            </div>
            <ul className="navbar-nav align-items-center">

              {
                <span className='m-2 btn btn-secondary'>{user}</span>
              }
              <button className='m-2 btn btn-danger' onClick={() => sing_out()}>Çıkış</button>
            </ul>
          </div>
        </div>
      </nav>
      : null}
    <NotificationContainer />
  </React.Fragment>
  );
}
